input.search {
  width: 100%;
  font-size: 2em;
  margin: 1em auto;
}

table {
  /* border: 1px solid #ccc; */
  width: 95%;
}

table th {
  text-align: left;
}
